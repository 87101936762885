@import "../../index.css";

.contactitem_Container {
  display: flex;
  flex-direction: column;
  background: var(--white-color);
  width: 100%;
  height: 5rem;
  border-bottom: 1px solid var(--light-border-color);
  cursor: pointer;
  align-items: flex-start;
  justify-content: center;
}

.contactitem_Container h6 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
  padding: 0.25rem;
}

.contactitem_Segment img {
  height: 1rem;
  padding: 0.25rem;
}

.contactitem_Container__Selected {
  display: flex;
  flex-direction: column;
  background: var(--background-color-300);
  width: 100%;
  height: 5rem;
  border-bottom: 1px solid var(--light-border-color);
  cursor: pointer;
  align-items: flex-start;
  justify-content: center;
}

.contactitem_Container__Selected img {
  height: 1rem;
  padding: 0.25rem;
}

.contactitem_Container__Selected h6 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
  padding: 0.25rem;
}

.contactitem_Segment {
  display: flex;
  padding: 0.15rem 1rem;
  align-items: center;
}
