@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap&family=Montserrat");

.modal {
  z-index: 100;
  position: fixed;
  top: 15vh;
  left: 30vw;
  width: 35%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 0.5rem;
}

.modal__header {
  background: var(--label-color);
  color: var(--button-text-color);
  border-radius: 8px 8px 0 0;
  text-align: center;
}

.modal__header h2 {
  font-family: var(--font-family);
  font-size: 1.2rem;
  font-weight: 600;
}

.modal__footer {
  padding: 1rem 0.5rem;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

.showmodal {
  padding: 20px 0 0 0;
}
