@import "../../index.css";

.customerjourney__Container {
  display: flex;
  margin: 0rem 2rem;
  padding: 1rem;
  border-left: 1px solid var(--dark-border-color);
}

.customerjourney__Container img {
  height: 1rem;
  margin-left: -1.35rem;
  background: var(--white-color);
  padding: 0.15rem 0;
}

.customerjourney__CallTime {
  display: flex;
  align-items: flex-end;
  margin: 0 0.75rem;
}

.customerjourney__CallTime h6 {
  color: var(--text-color);
  font-weight: 400;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  margin: 0 0.5rem;
}

.customerjourney__CallTime h5 {
  color: var(--text-color);
  font-weight: 400;
  font-size: var(--text-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}
