@import "../../index.css";

.form-control {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-control label {
  font-size: var(--text-font-size);
  font-family: var(--font-family);
  font-weight: 400;
  color: var(--primary-color);
  margin-bottom: 0 !important;
}

.form-control input {
  border: 1px solid var(--field-border-color);
  border-radius: var(--border-radius);
  padding: 0.65rem;
  font-size: var(--text-font-size);
  font-weight: 400;
  width: 90%;
}

.form-control textarea {
  border: 1px solid var(--field-border-color);
  border-radius: var(--border-radius);
  padding: 0.5rem;
  font-size: var(--text-font-size);
  font-weight: 400;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  border-color: var(--primary-color);
}

textarea::placeholder,
input::placeholder {
  font-size: var(--text-font-size);
  font-family: var(--font-family);
  font-size: var(--text-font-size);
  opacity: 0.5;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: var(--error-color);
  border-radius: var(--border-radius);
}
