@import "../../index.css";

.button {
  font: var(--font-family);
  padding: 0.6rem 2rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  color: var(--white-color);
  font-size: var(--text-font-size);
  font-family: var(--font-family);
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 4rem;
}

.button:hover,
.button:active {
  background: var(--primary-color-faded);
  border-color: var(--primary-color-faded);
  color: var(--white-color);
}

.button--default {
  background: var(--primary-color);
  color: var(--white-color);
  border: 1px solid var(--primary-color);
}

.button--default:hover,
.button--default:active {
  background: var(--primary-color-faded);
  border-color: var(--primary-color-faded);
  color: var(--white-color);
}

.button:focus {
  outline: none;
}

.button--inverse {
  background: var(--white-color);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.button--inverse:hover,
.button--inverse:active {
  border: 1px solid var(--primary-color);
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--standard {
  width: 6rem;
}

.button--danger {
  color: var(--white-color);
  background: var(--error-color);
  border: 1px solid var(--error-color);
}

.button--danger:hover,
.button--danger:active {
  border: 1px solid var(--error-color-500);
  background: var(--error-color-500);
}
