.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: fixed;
  height: 100%;
  width: 100%;
}

.App_section {
  display: flex;
  width: 100%;
  height: 100%;
}

.App_section__ccp_container {
  display: flex;
  width: 100%;
}
