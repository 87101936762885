@import "../../index.css";

.voicemailitem__Container {
  display: flex;
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: var(--element-color);
  align-items: center;
  border-bottom: 1px solid var(--light-border-color);
}

.voicemailitem__Customer {
  display: flex;
  flex-direction: column;
  width: 20%;
  justify-content: flex-start;
  align-items: flex-start;
}

.voicemailitem__Customer h6 {
  color: var(--text-color);
  font-weight: 400;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  margin: 0;
}

.voicemailitem__CustomerPhone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.voicemailitem__CustomerPhone img {
  height: 20px;
  margin-right: 0.5rem;
}

.voicemailitem__CustomerPhone h6 {
  color: var(--text-color);
  font-weight: 400;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  margin: 0;
}

.voicemailitem__CustomerName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.voicemailitem__CustomerName h6 {
  color: var(--text-color);
  font-weight: 400;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  margin: 0 1.4rem;
  opacity: 0.7;
}

.voicemailitem__CallTime {
  display: flex;
  flex-direction: column;
  width: 15%;
  align-items: flex-start;
}

.voicemailitem__CallTime h6 {
  color: var(--text-color);
  font-weight: 400;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  margin: 0;
}

.voicemailitem__CallTime h5 {
  color: var(--text-color);
  font-weight: 400;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}

.voicemailitem__Recording {
  display: flex;
  width: 35%;
}

.voicemailitem__Recording audio {
  width: 90%;
}

.voicemailitem__Download {
  display: flex;
  width: 10%;
}

.voicemailitem__Download img {
  height: 1.5rem;
  cursor: pointer;
  margin: 0 0.5rem;
}

.voicemail__Transcript__Modal {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.voicemail__Transcript__Modal__Header {
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.voicemail__Transcript__Modal__Header h2 {
  color: var(--primary-color);
  font-weight: 600;
  font-size: var(--largeheader-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}

.voicemail__Transcript__Modal__Voicemail {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.voicemail__Transcript__Modal__Voicemail h3 {
  color: var(--secondary-color);
  font-weight: 600;
  font-size: var(--header-font-size);
  font-family: var(--font-family);
  opacity: 1;
  margin: 0;
}

.voicemail__Transcript__Modal__Footer {
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: center;
}

.voicemail__Transcript__Modal__Footer__Button {
  display: flex;
  padding: 0 0.5rem;
}
