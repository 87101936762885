@import "../../index.css";

.voicemails__Container {
  width: 100%;
  height: 84%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
}

.voicemail__NoData {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.voicemail__NoData h2 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--header-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}

.voicemail__Loading {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
