@import "../../index.css";

.contacts__Container {
  width: 100%;
  flex-direction: column;
  height: 100vh;
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;
}

.contacts__Container__Header {
  display: flex;
  height: 10%;
  border-bottom: 1px solid var(--light-border-color);
  align-items: center;
  padding: 0 1rem;
  justify-content: space-between;
}

.contacts__Container__Header__Segment {
  display: flex;
}

.contacts__Container__Header__Filter {
  display: flex;
  border: 1px solid var(--field-border-color);
  border-radius: var(--border-radius);
  align-items: center;
  padding: 0.3rem 0.65rem;
}

.contacts__Container__Header__Filter:focus {
  border: 1px solid var(--primary-color);
}

.contacts__Container__Header__Filter img {
  height: 1rem;
  margin: 0 0.5rem;
}

.contacts__Container__Header__Filter input[type="text"] {
  border: none;
  background: transparent;
  font-weight: normal;
  font-family: var(--font-family);
  font-size: var(--text-font-size);
  padding: 0.25rem;
  width: inherit;
}

.contacts__Container__Header__Filter input:focus {
  outline: none;
}

.contacts__Container__Header__Filter input::placeholder {
  font-weight: normal;
  font-family: var(--font-family);
  font-size: var(--text-font-size);
}

.contacts__Container__Header__Sort {
  display: flex;
  margin: 0 0.5rem;
}

.contacts__Container__Header__Add {
  display: flex;
}

.contacts__Container__Body {
  display: flex;
  height: 90%;
  justify-content: center;
}

.contacts__ListContainer {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 82%;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.contacts__DataContainer {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid var(--light-border-color);
}

.contacts__Data__Body {
  display: flex;
  flex-direction: column;
}

.contacts__Data__Body__Edit {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 2rem;
}

.contacts__Data__Body__EditIcons {
  display: flex;
}

.contacts__Data__Body__EditIcons img {
  height: 1.2rem;
  margin-right: 1rem;
  cursor: pointer;
}

.contacts__Data__Body_RowSegment {
  display: flex;
  padding: 1rem;
}

.contacts__Data__Body_RowSegment__Data {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: flex-start;
  padding-left: 1rem;
}

.contacts__Data__Body_RowSegment__Data h3 {
  color: var(--secondary-color);
  font-weight: 600;
  font-size: var(--largeheader-font-size);
  font-family: var(--font-family);
  opacity: 1;
  margin: 0;
}

.contacts__Data__Body_RowSegment__Data h5 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}

.contacts__Data__Outbound {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contacts__Data__Outbound__Controls {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 2rem;
}

.contacts__Data__Outbound__Controls__Segment {
  display: flex;
  width: 90%;
  justify-content: center;
  padding: 0.5rem;
}

.contacts__Data__Outbound__Controls__Queue {
  display: flex;
  padding: 0 0.5rem;
  width: 40%;
}

.contacts__Data__Outbound__Controls__Phone {
  display: flex;
  padding: 0 0.5rem;
  width: 60%;
}

.contacts__NoDataContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.contacts__NoDataContainer h2 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--header-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}

.contacts__NoAuthContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.contacts__NoAuthContainer h2 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--header-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}

.contacts__Loading {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.contacts__Add__Modal {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.contacts__Add__Modal__Footer {
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: center;
}

.contacts__Add__Modal__Footer__Button {
  display: flex;
  padding: 0 0.5rem;
}

.contacts__Add__Modal__Header {
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.contacts__Add__Modal__Header h2 {
  color: var(--primary-color);
  font-weight: 600;
  font-size: var(--largeheader-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}

.contacts__Add__Model_RowSegment {
  display: flex;
  padding: 1rem;
  width: 90%;
}

.contacts__Add__Model_RowSegment__Phone {
  display: flex;
  width: 100%;
}

.contacts__Add__Model_RowSegment__Data__Phone {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding-left: 1rem;
}

.contacts__Add__Model_RowSegment__Data__Phone h3 {
  color: var(--secondary-color);
  font-weight: 600;
  font-size: var(--largeheader-font-size);
  font-family: var(--font-family);
  opacity: 1;
  margin: 0;
}

.contacts__Add__Model_RowSegment__Data__Phone h5 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}

.contacts__Add__Model_RowSegment__Data {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: flex-start;
  padding-left: 1rem;
}

.contacts__Add__Model_RowSegment__Data h3 {
  color: var(--secondary-color);
  font-weight: 600;
  font-size: var(--largeheader-font-size);
  font-family: var(--font-family);
  opacity: 1;
  margin: 0;
}

.contacts__Add__Model_RowSegment__Data h5 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}
