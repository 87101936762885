@import url("https://fonts.googleapis.com/css?family=Roboto");

.form-control {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-control label {
  font-size: var(--text-font-size);
  font-family: var(--font-family);
  font-weight: 400;
  color: var(--primary-color);
  margin-bottom: 0 !important;
}

.form-control select {
  width: 100%;
  border: 1px solid var(--field-border-color);
  border-radius: var(--border-radius);
  padding: 0.5rem;
  font-size: var(--text-font-size);
  font-family: var(--font-family);
  font-weight: 400;
}

.form-control select option {
  width: 100%;
  border: 1px solid var(--field-border-color);
  border-radius: var(--border-radius);
  padding: 0.15rem 0.25rem;
  font-size: var(--text-font-size);
  font-family: var(--font-family);
  font-weight: 400;
}

.form-control select:focus {
  outline: none;
  border-color: var(--field-border-color);
  font-size: var(--text-font-size);
  font-family: var(--font-family);
  font-weight: 400;
}
