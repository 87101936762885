@import "../../index.css";

.ccppanel_Container {
  display: flex;
  width: 100%;
  height: 100%;
}

.ccppanel_Panel {
  width: 30%;
  height: 100%;
  padding: 1rem;
}

.ccppanel_Panel iframe {
  border-style: none;
  border-radius: 0.5rem;
}

.ccppanel_Navigation {
  width: 70%;
  border-left: 1px solid var(--light-border-color);
}

.ccppanel_Panel__Loading {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ccppanel_Panel__Loading img {
  width: 2rem;
}

.ccppanel_Panel__Loading h2 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
}

.ccppanel_Panel__Loading p {
  color: var(--text-color);
  font-weight: 400;
  font-size: var(--text-font-size);
  font-family: var(--font-family);
  width: 70%;
}

.ccppanel__tabs_container {
  display: flex;
  background: var(--primary-color);
}

.ccppanel__tabs {
  display: flex;
  width: 12rem;
  border-right: 1px solid var(--white-color);
}

.ccppanel__tabs a {
  text-decoration: none;
}

.ccppanel__tabs__header {
  text-decoration: none;
}

.ccppanel__tabs__header h2 {
  color: var(--white-color);
  font-weight: 600;
  font-size: var(--header-font-size);
  font-family: var(--font-family);
  margin: 0;
}

.ccppanel__tabs__active {
  border-bottom: 5px solid var(--primary-color);
  padding: 0.5rem;
  background: var(--primary-color-faded);
  width: 100%;
  text-align: center;
  letter-spacing: 0.7px;
}

.ccppanel__tabs__inactive {
  padding: 0.5rem;
  width: 100%;
  text-align: center;
  letter-spacing: 0.7px;
}
