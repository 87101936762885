@import "../../index.css";

.callbackitem__Container {
  display: flex;
  background: var(--white-color);
  width: 100%;
  height: 5rem;
  border-bottom: 1px solid var(--light-border-color);
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.callbackitem__Container h6 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
  padding: 0.25rem 0;
}

.callbackitem__Container h5 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--text-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
  padding: 0.25rem 0;
}

.callbackitem__Segment__Column {
  display: flex;
  flex-direction: column;
}

.callbackitem__Segment__Column {
  display: flex;
  flex-direction: column;
}

.callbackitem__Segment {
  display: flex;
  padding: 0.15rem 1rem;
  align-items: center;
}

.callbackitem__Segment img {
  height: 1rem;
  padding: 0.25rem;
}

.callbackitem__Container__Selected {
  display: flex;
  background: var(--background-color-300);
  width: 100%;
  height: 5rem;
  border-bottom: 1px solid var(--light-border-color);
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.callbackitem__Container__Selected img {
  height: 1rem;
  padding: 0.25rem;
}

.callbackitem__Container__Selected h6 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
  padding: 0.25rem 0;
}

.callbackitem__Container__Selected h5 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--text-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
  padding: 0.25rem 0;
}
