@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600");

body {
  margin: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #002841;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0b476c;
}

:root {
  /* GENERAL */
  --font-family: "Nunito Sans", sans-serif;

  --primary-color: #2ba9cd;
  --primary-color-faded: #0b476c;
  --secondary-color: #0b476c;
  --error-color: #fd4f98;
  --error-color-500: #cb3071;

  --white-color: #ffffff;
  --text-color: #444;
  --element-color: #f2f2f2;

  --background-color-300: #f2f2f2;
  --background-color-500: #dbdbdb;

  --dark-border-color: #444;
  --light-border-color: #cfd7df;
  --field-border-color: #cfd7df;

  --text-font-size: 13px;
  --subtext-font-size: 11px;
  --header-font-size: 17px;
  --subheader-font-size: 15px;
  --largeheader-font-size: 20px;

  --border-radius: 5px;
}
