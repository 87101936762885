@import "../../../index.css";

.transcriptionitem__Container {
  display: flex;
  background: var(--background-color-300);
  padding: 1rem 0.5rem;
  margin: 0.5rem 0;
  border-radius: var(--border-radius);
  width: 70%;
  text-align: left;
}
