@import "../../index.css";

.App_header {
  width: 100%;
  display: flex;
  align-items: center;
  background: var(--white-color);
  box-shadow: 0 2px 6px rgb(0 0 0 / 26%);
  z-index: 5;
  justify-content: space-between;
}

.App_header__Image {
  display: flex;
  width: 40%;
}

.App_header__Image img {
  height: 4.5rem;
  padding: 0 1rem;
}

.App_header__Agent {
  display: flex;
  width: 12%;
  align-items: center;
}

.App_header__Agent__Avatar {
  border-radius: 50%;
  border: 1px solid var(--dark-border-color);
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App_header__Agent__Info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.App_header__Agent h5 {
  color: var(--text-color);
  padding: 0 1rem;
  margin: 0;
  font-weight: 400;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
}

.App_header__Agent h6 {
  padding: 0 1rem;
  font-weight: 400;
  margin: 0;
  font-size: var(--text-font-size);
  font-family: var(--font-family);
}
