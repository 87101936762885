@import "../../index.css";

.recordingitem__Container {
  display: flex;
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: var(--element-color);
  align-items: center;
  border-bottom: 1px solid var(--light-border-color);
}

.recordingitem__Customer {
  display: flex;
  flex-direction: column;
  width: 20%;
  justify-content: flex-start;
  align-items: flex-start;
}

.recordingitem__Customer h6 {
  color: var(--text-color);
  font-weight: 400;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  margin: 0;
}

.recordingitem__CustomerPhone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.recordingitem__CustomerPhone img {
  height: 20px;
  margin-right: 0.5rem;
}

.recordingitem__CustomerPhone h6 {
  color: var(--text-color);
  font-weight: 400;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  margin: 0;
}

.recordingitem__CustomerName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.recordingitem__CustomerName h6 {
  color: var(--text-color);
  font-weight: 400;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  margin: 0 1.4rem;
  opacity: 0.7;
}

.recordingitem__CallTime {
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: flex-start;
}

.recordingitem__CallTime h6 {
  color: var(--text-color);
  font-weight: 400;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  margin: 0;
}

.recordingitem__CallTime h5 {
  color: var(--text-color);
  font-weight: 400;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}

.recordingitem__Recording {
  display: flex;
  width: 40%;
}

.recordingitem__Recording audio {
  width: 90%;
}

.recordingitem__Download {
  display: flex;
  width: 7%;
}

.recordingitem__Download img {
  height: 1.5rem;
  cursor: pointer;
}
