@import "../../index.css";

.dashboard__Container {
  display: flex;
  height: 100%;
}

.dashboard__IncomingDetails {
  display: flex;
  width: 60%;
  height: 100%;
  flex-direction: column;
}

.dashboard__IncomingDetails__Data {
  display: flex;
  flex-direction: column;
}

.dashboard__IncomingDetails__Data__Header {
  display: flex;
  flex-direction: column;
}

.dashboard__IncomingDetails__Data__Segment {
  display: flex;
  padding: 1rem 0;
  align-items: center;
  margin: 0 5rem;
}

.dashboard__IncomingDetails__Data__Segment h2 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--largeheader-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}

.dashboard__IncomingDetails__Data__Segment h3 {
  color: var(--primary-color);
  font-weight: 600;
  font-size: var(--largeheader-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}

.dashboard__IncomingDetails__Data__Segment img {
  height: 2rem;
  margin-right: 1rem;
}

.dashboard__IncomingDetails__NoData__Header {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30%;
}

.dashboard__IncomingDetails__NoData__Outbound {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 2rem;
}

.dashboard__IncomingDetails__NoData__Outbound__Segment {
  display: flex;
  width: 90%;
  justify-content: center;
  padding: 0.5rem;
}

.dashboard__IncomingDetails__NoData__Outbound__Queue {
  display: flex;
  padding: 0 0.5rem;
  width: 40%;
}

.dashboard__IncomingDetails__NoData__Outbound__Phone {
  display: flex;
  padding: 0 0.5rem;
  width: 60%;
}

.dashboard__IncomingDetails__NoData {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dashboard__IncomingDetails__NoData__Body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  justify-content: center;
}

.dashboard__IncomingDetails__NoData__Body h2 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--header-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}

.dashboard__IncomingDetails__NoData__Body h3 {
  color: var(--text-color);
  font-weight: 400;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}

.dashboard__IncomingDetails__Data__Edit {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 2rem;
}

.dashboard__IncomingDetails__Data__EditIcons {
  display: flex;
}
.dashboard__IncomingDetails__Data__EditIcons img {
  height: 1.2rem;
  margin-right: 1rem;
  cursor: pointer;
}

.dashboard__IncomingDetails__Divider {
  display: flex;
  width: 80%;
}

.dashboard__IncomingDetails__Data__Body {
  display: flex;
  flex-direction: column;
}

.dashboard__IncomingDetails__Data__RowSegment {
  display: flex;
  padding: 1rem;
}

.dashboard__IncomingDetails__Data__RowSegment__Data {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: flex-start;
  padding-left: 1rem;
}

.dashboard__IncomingDetails__Data__RowSegment__Data h3 {
  color: var(--secondary-color);
  font-weight: 600;
  font-size: var(--largeheader-font-size);
  font-family: var(--font-family);
  opacity: 1;
  margin: 0;
}

.dashboard__IncomingDetails__Data__RowSegment__Data h5 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--subheader-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}

.dashboard__CustomerJourney {
  display: flex;
  width: 40%;
  border-left: 1px solid var(--light-border-color);
  justify-content: flex-start;
  flex-direction: column;
}

.dashboard__CustomerJourney__Header {
  display: flex;
  justify-content: center;
  height: 2rem;
  align-items: center;
  margin-bottom: 2rem;
}

.dashboard__CustomerJourney__Header h2 {
  color: var(--text-color);
  font-weight: 400;
  font-size: var(--header-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}

.dashboard__CustomerJourney__Data {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 2rem;
}

.dashboard__CustomerJourney__NoData {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: -2rem;
}

.dashboard__CustomerJourney__NoData h2 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--header-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}

.dashboard__IncomingDetails__NoAuth {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.dashboard__IncomingDetails__NoAuth h2 {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--header-font-size);
  font-family: var(--font-family);
  opacity: 0.7;
  margin: 0;
}
